import React, { createContext, useContext, useState, useEffect } from "react";
import { AUTH_PROFILE, AUTH_TOKEN } from "constants/AuthConstant";
import { API_URL } from "constants/ApiConstant";
import { get } from "services/ApiService"; // Assuming you are making API calls via this service
import { useLocation } from "react-router-dom";
import { getCookie } from "services/CookieService";

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const [dataProfile, setDataProfile] = useState(null);
  const location = useLocation(); // Track location changes

  const getProfile = async () => {
    try {
      const res = await get(API_URL.profile);
      if (res?.status === 200) {
        setDataProfile(res?.data?.data);
        localStorage.setItem(
          AUTH_PROFILE,
          JSON.stringify({
            ...res?.data?.data,
            company: {
              id: res?.data?.data?.current_company?.value,
              name: res?.data?.data?.current_company?.label,
            },
          })
        );
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  const checkSession = () => {
    const storedProfile = getCookie(AUTH_TOKEN);
    if (storedProfile) {
      getProfile();
    }
  };

  useEffect(() => {
    // This will trigger on every location change
    checkSession();
  }, [location]); // Dependency array includes 'location' to track changes

  return (
    <UserProfileContext.Provider value={dataProfile}>
      {children}
    </UserProfileContext.Provider>
  );
};

// Hook to use profile context
export const useGetProfile = () => {
  return useContext(UserProfileContext);
};
